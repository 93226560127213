import myself from "../src/media/myself.png";
import { motion } from "framer-motion";
import About from "./About";

function Main() {
  return (
    <>
      <div className="flex justify-center h-screen mt-10 ">
        <motion.div
          animate={{ x: 10, opacity: 1 }}
          initial={{ x: -650, opacity: 0 }}
          transition={{ delay: 0.4, duration: 1.5 }}
          className="w-1/2   flex justify-end mr-10 "
        >
          <img
            src={myself}
            alt={myself}
            className="w-3/4 h-4/5  rounded-md mt-14  shadow-2xl  shadow-blue-400 "
          />
        </motion.div>

        <motion.div
          initial={{ x: 650, opacity: 0 }}
          transition={{ delay: 0.4, duration: 1.5 }}
          animate={{ x: -10, opacity: 1 }}
          className="w-3/4   justify-center   text-white text-l font-bold font-mono mt-28"
        >
          <div className="w-3/4 mt-3 ml-8 text-base ">
            <h1>
              Hi,I'm{" "}
              <span className=" text-shadow-lg shadow-blue-200">
                {" "}
                Arundhati!👋
              </span>{" "}
            </h1>
            <h2>
              A{" "}
              <span className=" text-shadow-lg shadow-blue-200">
                Fullstack Developer{" "}
              </span>{" "}
              based in{" "}
              <span className=" text-shadow-lg shadow-blue-200">
                New York City 🗽
              </span>
              .{" "}
            </h2>
            <p className="mt-2 text-md">
              Resourceful and self-driven Web programmer with a passion for
              crafting exceptional user experiences through frontend
              engineering. Bringing a unique blend of technical expertise and
              problem solving skills to every project, I'm always eager to
              continue growing my skills and making meaningful contributions to
              the field of Web development. When I'm not coding ,you are likely
              going to see me at the gym 🏋️‍♀️.I also have a keen interest in world
              travel 🌎.
            </p>
          </div>
        </motion.div>
      </div>
    </>
  );
}
export default Main;
