import html from "../src/media/html.png";
import css from "../src/media/css.png";
import javascript from "../src/media/JS.png";
import react from "../src/media/react.png";
import bootstrap from "../src/media/bootstrap.png";
import mui from "../src/media/MUI.png";
import tailwind from "../src/media/tailwind.jpeg";
import git from "../src/media/git.png";
import sql from "../src/media/sql.png";
import psql from "../src/media/psql.png";
import ruby from "../src/media/ruby.png";
import java from "../src/media/java.jpeg";
import node from "../src/media/node.png";
import { motion, AnimatePresence } from "framer-motion";

function About() {
  return (
    <>
      <div className=" w-1/2 flex flex-col items-center bg-gradient-to-b from-cyan-800 to-blue-900  mt-8  ">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
        >
          <h1 className="text-shadow-lg font-serif text-center shadow-blue-200 text-slate-100 font-bold text-2xl mt-7 p-3 ">
            SKILLS
          </h1>
          <div className="grid grid-cols-4 gap-12 mt-4  items-stretch;">
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={html} alt={html} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                HTML
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={css} alt={css} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                CSS
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img
                src={javascript}
                alt={javascript}
                className="h-18 w-12 mb-1"
              />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                Javascript
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={react} alt={react} className="h-12 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                React
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={bootstrap} alt={bootstrap} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                Bootstrap
              </h2>
            </div>
            <div className="w-18 h-20 flex flex-col items-center m-2 ">
              <img src={mui} alt={mui} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                MUI
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={tailwind} alt={tailwind} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                Tailwind
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={git} alt={git} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                GIT
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-3 ">
              <img src={sql} alt={sql} className="h-full w-full mb-1" />
              <h2 className="text-slate-100 font-bold font-mono text-shadow-lg shadow-blue-500 m-1">
                SQL
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-3  xr-3">
              <img src={psql} alt={psql} className="h-18 w-12 mb-2" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1 mr-2 text-base">
                Psql
              </h2>
            </div>
            <div className="w-18 h-20 flex flex-col items-center m-2 ml-2">
              <img src={ruby} alt={ruby} className="h-19 w-12 mb-2" />
              <h2
                className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1  text-base"
                style={{ whiteSpace: "nowrap" }}
              >
                Ruby On Rails
              </h2>
            </div>
            <div className="w-18 h-20  flex flex-col items-center m-2 ">
              <img src={java} alt={java} className="h-12 w-12 mb-2" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-1">
                JAVA
              </h2>
            </div>
            <div className="w-18 h-20 flex flex-col items-center m-2 ">
              <img src={node} alt={node} className="h-18 w-12 mb-1" />
              <h2 className="text-slate-100 font-semibold font-mono text-shadow-lg shadow-blue-500 m-0.5">
                Node
              </h2>
            </div>
          </div>
        </motion.div>
      </div>

      <div className="w-1/2 flex flex-col items-center bg-gradient-to-b from-blue-900 to-cyan-900  mt-8">
        <h1 className="text-shadow-lg font-serif  shadow-blue-200 text-slate-100 font-bold text-2xl mt-7 p-3 ">
          EXPERIENCE
        </h1>
        <motion.div
          initial={{ scale: 0, opacity: 0.9 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            delay: 0.8,
            duration: 1.5,
          }}
        >
          <div className="items-start text-wrap  bg-slate-200 m-10  p-3 rounded-md mt-2  shadow-2xl  shadow-blue-400 ">
            <h2 className="font-bold">Junior Developer Intern</h2>
            <h2 className="font-semibold text-blue-900">
              Prifina-Liberty, Equality, Data{" "}
            </h2>
            <h6>
              September 2022- March 2023{" "}
              <span className="italic"> San Francisco,CA</span>
            </h6>
            <p>
              • Collaborated with developers and designers to transform Figma
              designs into code.
            </p>
            <p>
              • Built the front-end component of the app which would run on
              Prifina’s platform using personalized data.
            </p>
            <p>
              • Documented and oversaw the team’s progress with regards to the
              UI design, bugs & discussing the milestone.
            </p>
            <h2>
              <span className="font-bold">Skills:</span> ReactJS , Vanilla CSS
            </h2>
          </div>
        </motion.div>
      </div>
    </>
  );
}
export default About;

//bg-gradient-to-t from-cyan-800 to-blue-900
