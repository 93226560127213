import logo from "../src/media/logo.png";
import { useState } from "react";
import { motion } from "framer-motion";

const NavLinks = () => {
  return (
    <>
      <div className="mt-3 ">
        <ul className="flex items-end  mr-6 text-center sm:column-reverse">
          <li className="ml-5 sm:ml-5 mb-2">
            {" "}
            <a
              className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
              href="#about-section"
            >
              <h2>About</h2>
            </a>
          </li>

          <li className="ml-5 sm:ml-5 mb-2">
            <a
              href="#projects-section"
              className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
            >
              <h2>Projects</h2>
            </a>
          </li>
          <li className="ml-5  sm:ml-5 mb-2">
            {" "}
            <a
              href="#contact-section"
              className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
            >
              <h2>Contact</h2>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
function Navabar() {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleLinkClick = () => {
    setIsOpen(false); // Close the dropdown menu when a link is clicked
  };

  return (
    <>
      <nav class=" bg-blue-950 flex  z-[20] top-0 fixed w-full  ">
        <div className="md:flex text-white text-xl font-large font-bold font-mono py-2   w-full  ">
          <div className="  font-bold text-2xl flex  " style={{ flex: "1" }}>
            <motion.div
              animate={{ y: 2 }}
              initial={{ y: -100 }}
              transition={{ delay: 0.5 }}
            >
              <a href="/" className="flex text-shadow-lg shadow-blue-500">
                <img src={logo} className="h-10 w-10 ml-3 " />
                <p className="mt-1    ">rundhati Raicar</p>
              </a>
            </motion.div>
          </div>
          <motion.div
            animate={{ y: 2 }}
            initial={{ y: -100 }}
            transition={{ delay: 0.5 }}
          >
            <div className="md:block hidden ">
              <NavLinks />
            </div>
          </motion.div>
          <div className="absolute top-0 right-0">
            <button onClick={handleClick} className="md:hidden">
              {!isOpen ? (
                <h1 className="font-bold m-3 mt-5 text-3xl">☰</h1>
              ) : (
                <h1 className="font-bold top-0 m-3 mt-5 text-3xl">X</h1>
              )}
            </button>
          </div>
        </div>
      </nav>
      {isOpen && (
        <div className="md:hidden bg-blue-950 text-white text-lg font-bold pb-1 mt-14 z-50 fixed top-0  w-full">
          <ul className="   mr-6 ml-9 sm:column-reverse">
            <li className="ml-5 sm:ml-5 mb-2">
              {" "}
              <a
                className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
                href="#about-section"
                onClick={handleLinkClick}
              >
                <h2>About</h2>
              </a>
            </li>

            <li className="ml-5 sm:ml-5 mb-2">
              <a
                href="#projects-section"
                className="text-shadow-lg shadow-blue-500 hover:cursor-pointer"
              >
                <h2>Projects</h2>
              </a>
            </li>
            <li className="ml-5  sm:ml-5 mb-2">
              {" "}
              <a className="text-shadow-lg shadow-blue-500 hover:cursor-pointer">
                <h2>Contact</h2>
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}
export default Navabar;
