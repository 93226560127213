import git from "../src/media/git.png";
import medium from "../src/media/medium.png";
import linkedin from "../src/media/linkedin.png";
import coffee from "../src/media/coffee.jpeg";
function Contact() {
  return (
    <>
      <div className="bg-gradient-to-b from-cyan-800 to-blue-900 h-screen">
        <div className="  h-24 m-0 p-0 flex flex-col items-center  ">
          <h1 className="text-white font-bold text-3xl m-0 pt-20  mb-4 text-shadow-lg  align-center  shadow-blue-200 text-center ">
            Let's Connect 🌎
          </h1>
          <img
            src={coffee}
            alt="coffee"
            className="w-1/4 h-55  rounded-md mt-3 shadow-2xl  shadow-blue-400 "
          />
          <div className="grid grid-cols-3 text-white font-bold mt-7 text-shadow-lg align-center shadow-blue-200 text-center">
            <div className="h-10 w-10 flex flex-col items-center m-5 ">
              <img src={git} alt="git" />
              <a
                href="https://github.com/Aru12345"
                target="_blank"
                className=" ml-2"
              >
                GitHub
              </a>
            </div>

            <div className="h-10 w-10 flex flex-col items-center m-5">
              <img src={linkedin} alt="linkedin" />
              <a
                href="https://www.linkedin.com/in/arundhatiraicar/"
                target="_blank"
                className=" ml-2"
              >
                {" "}
                LinkedIn
              </a>
            </div>

            <div className="h-10 w-10 flex flex-col items-center m-5 bg-slate-100">
              <img src={medium} alt="medium" className="h-[100%] mb-4" />
              <a
                href="https://medium.com/@arundhatiraicar12"
                target="_blank"
                className=" ml-2"
              >
                {" "}
                Medium
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
