import logo from "./logo.svg";
import "./App.css";
import Navabar from "./Navbar";
import Main from "./Main";
import About from "./About";
import Projects from "./Projects";
import Contact from "./Contact";

function App() {
  return (
    <div className="App bg-gradient-to-b from-cyan-800 to-blue-900  ">
      <div className="flex flex-col h-screen">
        <Navabar />
        <Main className="flex-1" />
      </div>
      <div id="about-section" className="flex justify-center h-screen">
        <About />
      </div>
      <div id="projects-section" className="justify-center h-screen">
        <Projects />
      </div>
      <div id="contact-section" className="justify-center h-screen">
        <Contact />
      </div>
    </div>
  );
}

export default App;
